












































import Vue from 'vue';
import { IContactDataservice } from '../../../shared/src/dataservices/IContactDataservice';

export default Vue.extend({
  inject: {
    contactDataservice: 'contactDataservice'
  },
  computed: {
    contactPageActive(): boolean {
      return ((this as any).contactDataservice as IContactDataservice).contactPageActive;
    }
  },
  watch: {
    contactPageActive: {
      handler(newValue): void {
        if (!newValue) {
          this.$router.push({ name: 'home'});
        }
      }
    }
  },
});
